export default {
  lng: "vi", //vi|en
  region: "VN", //vi|pk
  version: "1",
  domain: "sieucapnhat.fo4.garena.vn",
  demo: 0,
  demoMsg: 'Sự kiện sẽ bắt đầu sau khi bảo trì 7.7 kết thúc',
  baseUrl:  `https://sieucapnhat.fo4.garena.vn`,
  shareHashtag: 'SieucapnhatFO4',
  shareQuote: 'Vào săn và trải nghiệm mùa thẻ 22TOTS miễn phí thôi anh em ơi!!!!',
  predictGuide: 'https://',
  spinGuide: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/tichdiem_eacc.png',
  prizeList: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/Aug2021/eacc/qua_ranking.png',
  prizeListGlobal: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/2022/T7/22TOTS/tots_qua.png',
  guideImg: 'https://cdn.vn.garenanow.com/web/fo4vn/Khoa/2022/T7/22TOTS/tots_hd.png',
};

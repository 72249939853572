import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Scrollbar from "react-scrollbars-custom";

import config from "config/common";
import lib from "lib/commons";
import Spinner from "components/Spinner";

Modal.setAppElement("#root");

const ModalHistory = ({
  matches,
  userHistory,
  voteHistory,
  isGettingHistory,
  getHistory,
  modalStatus,
  closeModal,
}) => {
  const [currentTab, setCurrentTab] = useState("gifts");
  const matchesInfo = lib.toObj(matches, "id");

  useEffect(() => {
    if (!isGettingHistory) {
      getHistory();
    }
  }, []);

  const afterOpenModal = () => {};

  const selfClose = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={modalStatus}
      onAfterOpen={afterOpenModal}
      onRequestClose={selfClose}
      contentLabel="Example Modal"
      portalClassName="ReactModalPortal"
      overlayClassName=""
      className="animated fadeInDown faster modal-history"
    >
      <div className="modal-description">
        <h2>Lịch sử</h2>
        {currentTab == "gifts" && (
          <>
            {isGettingHistory ? (
              <Spinner />
            ) : (
              <>
                <table className="table-history">
                  <thead>
                    <tr>
                      <th width="10%">STT</th>
                      <th width="30%">Tên VP</th>
                      <th width="15%">Trạng thái</th>
                      <th width="25%">Thời gian</th>
                      <th width="20%">Sự kiện</th>
                    </tr>
                  </thead>
                </table>

                <Scrollbar style={{ height: "26vw" }} noScrollX={true}>
                  {userHistory.length > 0 ? (
                    <table className="table-history">
                      <tbody>
                        {userHistory.map((his, index) => {
                          let status = ''
                          if(his.usage == 'spin') {
                            status = 'Ô quà nâng cấp'
                          }
                          if(his.usage == 'churn') {
                            status = 'Quà tri ân'
                          }
                          if(his.usage == 'exchange') {
                            status = 'Quà huy hiệu'
                          }
                          return (
                            <tr key={index}>
                              <td width="10%">
                                <strong>{index + 1}</strong>
                              </td>
                              <td width="30%">{his.reward_name}</td>
                              <td width="15%">{his.status}</td>
                              <td width="25%">{his.create_time}</td>
                              <td width="20%">
                                {status}
                              </td>
                            </tr>
                          )
                      })}
                      </tbody>
                    </table>
                  ) : (
                    <p>Chưa có lịch sử</p>
                  )}
                </Scrollbar>
              </>
            )}
          </>
        )}
      </div>
      <a
        onClick={(event) => selfClose(event)}
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        ×
      </a>
    </Modal>
  );
};
export default ModalHistory;

import request from "lib/request"
import config from "config/app"
import lib from 'lib/commons'
import lang from "lng/index"
const lng = lang[config.lng]

export const CURRENT_USER_REQUEST         = 'CURRENT_USER_REQUEST'
export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR'
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS'
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING'

export const SPIN         = 'SPIN'
export const SPIN_ERROR   = 'SPIN_ERROR'
export const SPIN_SUCCESS = 'SPIN_SUCCESS'
export const IS_SPINNING  = 'IS_SPINNING'

export const SHARE         = 'SHARE'
export const SHARE_ERROR   = 'SHARE_ERROR'
export const SHARE_SUCCESS = 'SHARE_SUCCESS'
export const IS_SHARING    = 'IS_SHARING'

export const CHECK_MATCH         = 'CHECK_MATCH'
export const CHECK_MATCH_ERROR   = 'CHECK_MATCH_ERROR'
export const CHECK_MATCH_SUCCESS = 'CHECK_MATCH_SUCCESS'
export const IS_CHECKING_MATCH   = 'IS_CHECKING_MATCH'

export const CLAIM_MISSION         = 'CLAIM_MISSION'
export const CLAIM_MISSION_ERROR   = 'CLAIM_MISSION_ERROR'
export const CLAIM_MISSION_SUCCESS = 'CLAIM_MISSION_SUCCESS'
export const IS_MISSION_CLAIMING   = 'IS_MISSION_CLAIMING'

export const CLAIM_EXCHANGE_ERROR   = 'CLAIM_EXCHANGE_ERROR'
export const CLAIM_EXCHANGE_SUCCESS = 'CLAIM_EXCHANGE_SUCCESS'
export const IS_EXCHANGE_CLAIMING   = 'IS_EXCHANGE_CLAIMING'

export const DO_CHECK_CHURN_ERROR    = 'DO_CHECK_CHURN_ERROR'
export const DO_CHECK_CHURN_SUCCESS  = 'DO_CHECK_CHURN_SUCCESS'
export const DO_CHECK_CHURN_LOADING  = 'DO_CHECK_CHURN_LOADING'

export const GET_HISTORY_ERROR   = 'GET_HISTORY_ERROR'
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS'
export const IS_GETTING_HISTORY  = 'IS_GETTING_HISTORY'

export const REGISTER         = 'REGISTER'
export const REGISTER_ERROR   = 'REGISTER_ERROR'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const IS_REGISTERING   = 'IS_REGISTERING'

let tm

export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = () => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
      } else {
        if(response.error_code == 'no_account') {
          lib.showDownlad()
        }
        dispatch(getCurrentUserError(response))
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response.payload
  }
}

//Spin
export const isSpinning = (response) => {
  return {
    type: IS_SPINNING,
  }
}
export const spin = (setWinningItem) => {
  return (dispatch, getState) => {
    dispatch(isSpinning());
    request('api/user/spin', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {

        console.log(getState())

        let items = getState().currentUser.spinRewards
        let itemArray = []
        for (let index = 0; index < items.length; index++) {
          const item = items[index]

          itemArray.push(item.id)
        }

        let winItem = response.payload.reward

        let shuffledArray = lib.shuffleArray([...itemArray])
        let currentIndex = 0
        let turns = 0
        let maxTurn = itemArray.length == 1 ? 1 : 6

        tm = setInterval(() => {
          if(currentIndex == shuffledArray.length) {
            currentIndex = 0
          }
          if(turns >= maxTurn && shuffledArray[currentIndex] == winItem.id) {
            clearInterval(tm)
            $(`.spin__item--${winItem.id}`).css('animation', 'won 1.5s')
            setTimeout(() => {
              $(`.spin__item--${winItem.id}`).css('animation', 'none')
              lib.showMessage(`Bạn đã nhận được:<br><div class="item-result"><img src="${winItem.image}" alt="" class="item-cover__img" /></div><p>${winItem.product_name}</p>`, null, 'Chúc mừng!').then(res => {
                dispatch(spinSuccess(response))
              })
            }, 1500)
          }

          setWinningItem(shuffledArray[currentIndex])
          currentIndex++
          turns++
        }, 400)

      } else {
        lib.showError(response.error_code)
        dispatch(spinError(response))
      }
    })
  }
}
export const spinSuccess = (response) => {
  return {
    type: SPIN_SUCCESS,
    payload: response.payload
  }
}
export const spinError = (response) => {
  return {
    type: SPIN_ERROR,
    payload: response.payload
  }
}

//Share
export const isSharing = (response) => {
  return {
    type: IS_SHARING,
  }
}
export const share = (shareCustom = null) => {
  return (dispatch, getState) => {
    dispatch(isSharing());
    request('api/user/share', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        if(shareCustom) {
          shareCustom()
        } else {
          lib.showMessage('<p class="text-center">Chia sẻ thành công!</p>')
        }
        dispatch(shareSuccess(response))
      } else {
        lib.showError(response.error_code)
        dispatch(shareError(response))
      }
    })
  }
}
export const shareSuccess = (response) => {
  return {
    type: SHARE_SUCCESS,
    payload: response.payload
  }
}
export const shareError = (response) => {
  return {
    type: SHARE_ERROR,
    payload: response.payload
  }
}

//CHECK MATCH
export const isCheckingMatch = (response) => {
  return {
    type: IS_CHECKING_MATCH,
  }
}
export const checkMatch = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingMatch());
    request('api/user/check-mission', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`
          <div class="row match-progress">
            <div class="col-5">
              <p>Chơi trận XH/GLXH <br />VOLTA/ARENA</p>
            </div>
            <div class="col-7">
              <img src="/images/play${response.payload.total_play}.png" alt="" class="play-progress" />
            </div>
            <div class="w-100"></div>
            <div class="col-5">
              <p>Chia sẻ Facebook</p>
            </div>
            <div class="col-7">
              <img src="/images/share${response.payload.user.shared ? 1 : 0}.png" alt="" class="play-progress-share" />
            </div>
          </div>
        `, null, 'Tiến độ nhiệm vụ', null).then(res => {
          dispatch(checkMatchSuccess(response))
        })
      } else {
        lib.showError(response.error_code)
        dispatch(checkMatchError(response))
      }
    })
  }
}
export const checkMatchSuccess = (response) => {
  return {
    type: CHECK_MATCH_SUCCESS,
    payload: response.payload
  }
}
export const checkMatchError = (response) => {
  return {
    type: CHECK_MATCH_ERROR,
    payload: response
  }
}

//Claim missions
export const isMissionClaiming = (response) => {
  return {
    type: IS_MISSION_CLAIMING,
  }
}
export const claimMission = (prize, type) => {
  return (dispatch, getState) => {
    dispatch(isMissionClaiming());
    request('api/user/claim-churn', 'POST', {
      body: JSON.stringify({
        reward_id: parseInt(prize.id),
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`Bạn đã nhận được:<br><div class="item-result"><img src="${response.payload.reward?.image}" alt="" class="item-result__img" /></div><p>${response.payload.reward?.product_name}</p>`, null, 'Chúc mừng!')
        dispatch(claimMissionSuccess(response))
      } else {
        lib.showError(response.error_code)
        dispatch(claimMissionError(response))
      }
    })
  }
}
export const claimMissionSuccess = (response) => {
  return {
    type: CLAIM_MISSION_SUCCESS,
    payload: response.payload
  }
}
export const claimMissionError = (response) => {
  return {
    type: CLAIM_MISSION_ERROR,
    payload: response.payload
  }
}

// Do check churn mission
export const isCheckingChurn = () => {
  return {
    type: DO_CHECK_CHURN_LOADING
  };
}

export const doCheckChurnSuccess = (response) => {
  return {
    type: DO_CHECK_CHURN_SUCCESS,
    payload: response.payload
  };
}

export const doCheckChurnError = (response) => {
  return {
    type: DO_CHECK_CHURN_ERROR,
    payload: response
  };
}

export const doCheckChurn = () => {
  return (dispatch, getState) => {
    dispatch(isCheckingChurn());
    request('api/user/check-churn-mission', 'POST', {
      body: JSON.stringify({})
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`Cập nhật thành công`)
        dispatch(doCheckChurnSuccess(response));
      } else {
        lib.showError(response?.error_code);
        dispatch(doCheckChurnError(response));
      }
    });
  };
}

//Claim exchange
export const isExchangeClaiming = (response) => {
  return {
    type: IS_EXCHANGE_CLAIMING,
  }
}
export const claimExchange = (prize, type) => {
  return (dispatch, getState) => {
    dispatch(isExchangeClaiming());
    request('api/user/claim-exchange', 'POST', {
      body: JSON.stringify({
        reward_id: parseInt(prize.id),
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`Bạn đã nhận được:<br><div class="item-result"><img src="${response.payload.reward?.image}" alt="" class="item-result__img" /></div><p>${response.payload.reward?.product_name}</p>`, null, 'Chúc mừng!')
        dispatch(claimExchangeSuccess(response))
      } else {
        lib.showError(response.error_code)
        dispatch(claimExchangeError(response))
      }
    })
  }
}
export const claimExchangeSuccess = (response) => {
  return {
    type: CLAIM_EXCHANGE_SUCCESS,
    payload: response.payload
  }
}
export const claimExchangeError = (response) => {
  return {
    type: CLAIM_EXCHANGE_ERROR,
    payload: response.payload
  }
}

//get history
export const isGettingHistory = () => {
  return {
    type: IS_GETTING_HISTORY
  }
}

export const getHistory = () => {
  return (dispatch, getState) => {
    dispatch(isGettingHistory());
    request('api/user/history').then(function(response) {
      if(response.status == 'successful') {
        dispatch(getHistorySuccess(response))
      } else {
        dispatch(getHistoryError(response))
      }
    })
  }
}

export const getHistorySuccess = (response) => {
  return {
    type: GET_HISTORY_SUCCESS,
    payload: response.payload
  }
}

export const getHistoryError = (response) => {
  return {
    type: GET_HISTORY_ERROR,
    payload: response.payload
  }
}

//Register
export const isRegistering = (response) => {
  return {
    type: IS_REGISTERING,
  }
}
export const register = (data, closeModal) => {
  return (dispatch, getState) => {
    dispatch(isRegistering());
    request('api/user/update-info', 'POST', {
      body: JSON.stringify({
        name: data.inputName,
        phone: data.inputPhone,
        address: data.inputAddress,
      })
    }).then(function(response) {
      if(response.status == 'successful') {
        lib.showMessage(`Bạn đã đăng ký thành công<br>Chúng tôi sẽ sớm liên hệ với bạn!`, null, 'Chúc mừng!').then(res => {
          closeModal()
          dispatch(registerSuccess(response))
        })

      } else {
        lib.showError(response.error_code)
        dispatch(registerError(response))
      }
    })
  }
}
export const registerSuccess = (response) => {
  return {
    type: REGISTER_SUCCESS,
    payload: response.payload
  }
}
export const registerError = (response) => {
  return {
    type: REGISTER_ERROR,
    payload: response
  }
}

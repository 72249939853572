import config from 'config/app'
import lib from 'lib/commons'

import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  SPIN_ERROR,
  SPIN_SUCCESS,
  IS_SPINNING,

  SHARE_ERROR,
  SHARE_SUCCESS,
  IS_SHARING,

  CHECK_MATCH_ERROR,
  CHECK_MATCH_SUCCESS,
  IS_CHECKING_MATCH,

  CLAIM_MISSION_ERROR,
  CLAIM_MISSION_SUCCESS,
  IS_MISSION_CLAIMING,

  CLAIM_EXCHANGE_ERROR,
  CLAIM_EXCHANGE_SUCCESS,
  IS_EXCHANGE_CLAIMING,

  DO_CHECK_CHURN_ERROR,
  DO_CHECK_CHURN_SUCCESS,
  DO_CHECK_CHURN_LOADING,

  GET_HISTORY_ERROR,
  GET_HISTORY_SUCCESS,
  IS_GETTING_HISTORY,

  REGISTER_ERROR,
  REGISTER_SUCCESS,
  IS_REGISTERING,

} from './actions'

const initialState = {
  loading: false,
  login: false,
  user: {},
  churnRewards: [],
  currentDatetime: null,
  eventStarted: 0,
  exchangeRewards: [],
  spinRewards: [],
  youtubeInfos: [],

  isSpinning: false,
  isCheckingMatch: false,
  isSharing: false,
  isMissionClaiming: false,
  isExchangeClaiming: false,
  loadingCheckChurn: false,
  isGettingHistory: false,
  isRegistering: false
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {
  switch (type) {
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        churnRewards: payload.churn_rewards,
        currentDatetime: payload.current_datetime,
        eventStarted: payload.event_started,
        exchangeRewards: payload.exchange_rewards,
        spinRewards: payload.spin_rewards,
        youtubeInfos: payload.youtube_infos,
        errorGlobal: ''
      }
      break;
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        churnRewards: payload.churn_rewards,
        currentDatetime: payload.current_datetime,
        eventStarted: payload.event_started,
        exchangeRewards: payload.exchange_rewards,
        spinRewards: payload.spin_rewards,
        youtubeInfos: payload.youtube_infos,
        errorGlobal: 'Không có thông tin user',
      }
      break;
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      }
      break;

      //SPIN
      case SPIN_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            spun_times: payload.user.spun_times,
            spin_times: payload.user.spin_times,
            exchange_points: payload.user.exchange_points
          },
          exchangeRewards: payload.exchange_rewards,
          spinRewards: payload.spin_rewards,
          isSpinning: false
        }
        break;
      case SPIN_ERROR:
        return {
          ...state,
          isSpinning: false
        }
        break;
      case IS_SPINNING:
        return {
          ...state,
          isSpinning: true
        }
        break;

    //SHARE
    case SHARE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          shared: payload.user.shared,
          spin_times: payload.user.spin_times,
        },
        isSharing: false
      }
      break;
    case SHARE_ERROR:
      return {
        ...state,
        isSharing: false
      }
      break;
    case IS_SHARING:
      return {
        ...state,
        isSharing: true
      }
      break;

      //CHECK MATCH
      case CHECK_MATCH_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            spin_times: payload.user.spin_times,
            shared: payload.user.shared,
            match_play: payload.user.match_play,
          },
          isCheckingMatch: false
        }
        break;
      case CHECK_MATCH_ERROR:
        return {
          ...state,
          isCheckingMatch: false
        }
        break;
      case IS_CHECKING_MATCH:
        return {
          ...state,
          isCheckingMatch: true
        }
        break;

    //CLAIM MISSION
    case CLAIM_MISSION_SUCCESS:
      return {
        ...state,
        churnRewards: payload.churn_rewards,
        isMissionClaiming: false
      }
      break;
    case CLAIM_MISSION_ERROR:
      return {
        ...state,
        isMissionClaiming: false
      }
      break;
    case IS_MISSION_CLAIMING:
      return {
        ...state,
        isMissionClaiming: true
      }
      break;

    //CLAIM EXCHANGE
    case CLAIM_EXCHANGE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          exchange_points: payload.user.exchange_points,
        },
        exchangeRewards: payload.exchange_rewards,
        isExchangeClaiming: false
      }
      break;
    case CLAIM_EXCHANGE_ERROR:
      return {
        ...state,
        isExchangeClaiming: false
      }
      break;
    case IS_EXCHANGE_CLAIMING:
      return {
        ...state,
        isExchangeClaiming: true
      }
      break;

    // CHECK CHURN MISSION
    case DO_CHECK_CHURN_SUCCESS:
      return {
        ...state,
        loadingCheckChurn: false,
        churnRewards: payload.churn_rewards
      };
      break;
    case DO_CHECK_CHURN_ERROR:
      return {
        ...state,
        loadingCheckChurn: false,
      };
      break;
    case DO_CHECK_CHURN_LOADING:
      return {
        ...state,
        loadingCheckChurn: true,
      };
      break;

    //GET HISTORY
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        userHistory: payload,
        isGettingHistory: false
      }
      break;
    case GET_HISTORY_ERROR:
      return {
        ...state,
        isGettingHistory: false
      }
      break;
    case IS_GETTING_HISTORY:
      return {
        ...state,
        isGettingHistory: true
      }
      break;

    //REGISTER
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          need_info: payload?.user?.need_info,
        },
        isRegistering: false
      }
      break;
    case REGISTER_ERROR:
      return {
        ...state,
        isRegistering: false
      }
      break;
    case IS_REGISTERING:
      return {
        ...state,
        isRegistering: true
      }
      break;

    default:
      return state
  }
}
